import React from 'react'
import Layout from '../components/layout'
import '../pages/styles/index.scss'
import Head from '../components/head'
import Landing from '../components/landing'
import Blogs from '../components/blogs'
const Index = () => {






    return(
        <div>
            <Layout>
            <Head title="Home" />
            <Landing />
            <Blogs />
            
            </Layout>
        </div>
    )
}

export default Index