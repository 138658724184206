import React from 'react'
import {Link,  graphql, useStaticQuery} from 'gatsby'
import blogstyle from '../pages/styles/blog.module.scss'


const Blog = () => {

    const data = useStaticQuery(graphql`
    query{
    allContentfulBlogPost (
        sort: {
          fields: publishedDate,
          order: DESC
        }
  ){
    edges{
      node{
        title
        slug
        author
        category
        shortDisplayText

        publishedDate(formatString:"MMMM Do, YYYY")
      }
    }
  }
}
    `)





    return(
        <div className={blogstyle.container}>


            <ol className={blogstyle.list} >
                {data.allContentfulBlogPost.edges.map((edge) => {
                 return (
                     <>
                     <Link to={`/blog/${edge.node.slug}`}>
                     <div  className={blogstyle.blogArea}>
                            <li className={blogstyle.list}>
                         <h2 className={blogstyle.title}>{edge.node.title}</h2>
                         <p className={blogstyle.date}>
                             {edge.node.publishedDate}
                         </p>
                          <p className={blogstyle.displaytext}>{edge.node.shortDisplayText}</p>

                         <p className={blogstyle.p}>By <span className={blogstyle.author}>{edge.node.author} </span>in <span className={blogstyle.category}>{edge.node.category}</span></p>

                         <p className={blogstyle.read}>
                              Read More 
                         </p>
                
                            </li>
                     </div>
                     </Link>
                     </>
                     
                 )
                         })}
            </ol>

        </div>
    )
}

export default Blog