import React from 'react'
import linestyle from './line.module.scss'

const line = () => {
    return (
        <div className={linestyle.line}>
            
        </div>
    )
}

export default line
