import React from 'react'
import landingstyle from './landing.module.scss'
import Line from './line'
const landing = () => {
    return (
        <div className={landingstyle.container}>
        <Line />
            <h1>Blog</h1>
            <p>The latest news about our services and products from the VID VEDA team
 </p>
         <Line />

        </div>
    )
}

export default landing
